import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import { configureStore } from '@reduxjs/toolkit';
import rootReducer from "./components/store/rootReducer";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
import "./components/assets/skeleton.scss";
import "./components/nav/menu.scss";

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['USER_IS_ACTIVE'], // Ignore this action type
        ignoredPaths: ['user.dateRegistered'], // Ignore this path where the non-serializable value is
      },
    }),
});

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

serviceWorker.unregister();
