import React from "react";
import { NavLink } from "react-router-dom";
import { AccountCircle } from "@mui/icons-material";
import navStyles from "./nav.module.scss";

const Active = (props) => {
  const toggle = props.logic ? "open" : "closed";
  return (
    <ul className={"nav " + toggle}>
      <li>
        <NavLink to="/about" className={({ isActive }) => (isActive ? navStyles.active : undefined)}>
          About
        </NavLink>
      </li>
      <li>
        <NavLink to="/explore-charts" className={({ isActive }) => (isActive ? navStyles.active : undefined)}>
          Explore Charts
        </NavLink>
      </li>
      <li>
        <NavLink to="/user/saved-tracks" className={({ isActive }) => (isActive ? navStyles.active : undefined)}>
          Saved
        </NavLink>
      </li>
      <li className="user">
        <NavLink to="/profile" className={({ isActive }) => (isActive ? navStyles.active : undefined)}>
          <AccountCircle className="icon" />
          <span className="username">Hi, {props.username}</span>
        </NavLink>
      </li>
    </ul>
  );
};

export default Active;
