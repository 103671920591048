import React from "react";
import { NavLink } from "react-router-dom";
import navStyles from "./nav.module.scss";

const NotActive = (props) => {
  const toggle = props.logic ? "open" : "closed";
  return (
    <ul className={"nav " + toggle}>
      <li>
        <NavLink
          to="/about"
          className={({ isActive }) => (isActive ? navStyles.active : undefined)}
        >
          About
        </NavLink>
      </li>
      <li>
        <NavLink
          to="/explore-charts"
          className={({ isActive }) => (isActive ? navStyles.active : undefined)}
        >
          Explore Charts
        </NavLink>
      </li>
      <li>
        <NavLink to="/signup">Sign up</NavLink>
      </li>
      <li>
        <NavLink to="/login">Login</NavLink>
      </li>
    </ul>
  );
};

export default NotActive;
