import { auth, db, collection, doc, setDoc, getDoc, updateDoc, query, where, getDocs } from "../../../configs/firebase/config";

import { createUserWithEmailAndPassword, signOut, signInWithEmailAndPassword,
   sendPasswordResetEmail, confirmPasswordReset, sendEmailVerification, updateEmail, EmailAuthProvider, reauthenticateWithCredential, applyActionCode } from "firebase/auth";

//user Signup action
export const userSignup = (username, email, password) => {
  return async (dispatch) => {
    dispatch({ type: "SIGNUP_START" });
    try {
      const cred = await createUserWithEmailAndPassword(auth, email, password);
      const userProfile = {
        id: cred.user.uid,
        username,
        email,
        likedTracks: [],
        dateRegistered: new Date(),
        emailVerified: false,
       // profilePicUrl: "" 
      };
    
      await setDoc(doc(collection(db, "users"), cred.user.uid), userProfile);
      
      dispatch({ type: "SIGNUP_SUCCESS", user: userProfile });
      return cred;
    } catch (err) {
      let errorMsg;
      switch (err.code) {
        case "auth/email-already-in-use":
          errorMsg = "Email already in use.";
          break;
        case "auth/network-request-failed":
          errorMsg = "Network Failure. Please try again later";
          break;
        default:
          errorMsg = `Error signing up: ${err.message}`;
      }
      dispatch({ type: "SIGNUP_ERROR", err: errorMsg });
    }
  };
};

// User logging in action creators
export const userLogin = (email, password) => {
  return async (dispatch) => {
    dispatch({ type: "LOGIN_START" });
    try {
      const cred = await signInWithEmailAndPassword(auth, email, password);
      const userDoc = await getDoc(doc(db, "users", cred.user.uid));
      const userData = userDoc.data();
      const dateRegistered = userData.dateRegistered.toDate().toISOString(); // Converted Firestore Timestamp to ISO string
      dispatch({ type: "LOGIN_SUCCESS", user: { ...userData, dateRegistered } });
      return cred;
    } catch (err) {
      let errorMessage;
      switch (err.code) {
        case "auth/invalid-credential":
          errorMessage = "Wrong Email or Password.";
          break;
        case "auth/invalid-credential":
        errorMessage = "Wrong Email or Password.";
        break;
        case "auth/wrong-password":
          errorMessage = "Wrong Password";
          break;
        case "auth/network-request-failed":
          errorMessage = "Network Failure. Please try again later";
          break;
        case "auth/user-not-found":
          errorMessage = "No user found with that Email";
          break;
        default:
          errorMessage = `Error logging in: ${err.message}`;
      }
      dispatch({ type: "LOGIN_ERROR", err: errorMessage });
      return null;
    }
  };
};

//send verify email action creator
const VERIFY_EMAIL_START = "VERIFY_EMAIL_START";
const VERIFY_EMAIL_SUCCESS = "VERIFY_EMAIL_SUCCESS";
const VERIFY_EMAIL_ERROR = "VERIFY_EMAIL_ERROR";

export const verifyEmail = () => {
  return async (dispatch) => {
    dispatch({ type: VERIFY_EMAIL_START });
    try {
      const user = auth.currentUser;
     
      if (user) {
        await sendEmailVerification(user);
        dispatch({ type: VERIFY_EMAIL_SUCCESS });
      } else {
        throw new Error("No user is signed in.");
      }
    } catch (err) {
      dispatch({ type: VERIFY_EMAIL_ERROR, err: err.message });
    }
  };
};

// Action types
const VERIFIED_EMAIL_START = "VERIFIED_EMAIL_START";
const VERIFIED_EMAIL_SUCCESS = "VERIFIED_EMAIL_SUCCESS";
const VERIFIED_EMAIL_ERROR = "VERIFIED_EMAIL_ERROR";

// Action creators
export const verifyEmailInFirestore = (oobCode) => {
  return async (dispatch) => {
    dispatch({ type: VERIFIED_EMAIL_START });

    try {
      await applyActionCode(auth, oobCode);
      const user = auth.currentUser;
      if (user) {
        const userRef = doc(db, "users", user.uid);
        await updateDoc(userRef, { emailVerified: true });

        dispatch({ type: VERIFIED_EMAIL_SUCCESS, payload: true }); 
      } else {
        throw new Error("No user is signed in.");
      }
    } catch (error) {
     
      dispatch({ 
        type: VERIFIED_EMAIL_ERROR, 
        error: error.code === "auth/invalid-action-code" 
          ? "Invalid or expired verification link." 
          : "Email verification failed. Try again later." 
      });
    }
  };
};

// forgot password action creators
export const userForgotPassword = (email, navigate) => {
  return async (dispatch) => {
    dispatch({ type: "FORGOT_PASSWORD_START" });
    try {
      await sendPasswordResetEmail(auth, email);
      dispatch({ type: "FORGOT_PASSWORD_SUCCESS" });
      alert("Password reset email sent successfully. Check your email inbox.");
      navigate("/");
    } catch (err) {
      let errorMessage;
      switch (err.code) {
        case "auth/user-not-found":
          errorMessage = "No user found with this email.";
          break;
        case "auth/invalid-email":
          errorMessage = "Invalid email address.";
          break;
        case "auth/network-request-failed":
          errorMessage = "Network Failure. Please try again later.";
          break;
        default:
          errorMessage = `Error sending password recovery email: ${err.message}`;
      }
      dispatch({ type: "FORGOT_PASSWORD_ERROR", err: errorMessage });
    }
  };
};


// reset password action creators
export const userResetPassword = (oobCode, newPassword, navigate) => {
  return async (dispatch) => {
    dispatch({ type: "RESET_PASSWORD_START" });
    try {
      await confirmPasswordReset(auth, oobCode, newPassword);
      dispatch({ type: "RESET_PASSWORD_SUCCESS" });
      alert("Password updated successfully. Login with new password");
      navigate("/login");
    } catch (err) {
      let errorMessage;
      switch (err.code) {
        case "auth/expired-action-code":
          errorMessage = "The reset link has expired.";
          break;
        case "auth/invalid-action-code":
          errorMessage = "The reset link is invalid.";
          break;
        case "auth/weak-password":
          errorMessage = "The new password is too weak.";
          break;
        default:
          errorMessage = `Error updating password: ${err.message}`;
      }
      dispatch({ type: "RESET_PASSWORD_ERROR", err: errorMessage });
    }
  };
};


// User logging out action creators
const userLogoutStart = () => ({ type: "LOGOUT_START" });
const userLogoutSuccess = () => ({ type: "LOGOUT_SUCCESS" });
const userLogoutError = (err) => ({ type: "LOGOUT_ERROR", err });

export const userLogout = () => {
  return async (dispatch) => {
    dispatch(userLogoutStart());
    try {
      await signOut(auth);
      dispatch(userLogoutSuccess());
    } catch (err) {
      dispatch(userLogoutError(`Error logging out: ${err.message}`));
    }
  };
};

//user saved track actions
const userSaveTrackStart = () => ({ type: "SAVE_TRACK_START" });
const userSaveTrackSuccess = (likedTracks) => ({ type: "SAVE_TRACK_SUCCESS", likedTracks });
const userSaveTrackError = (err) => ({ type: "SAVE_TRACK_ERROR", err });

export const userSaveTrack = (userId, track) => {
  return async (dispatch) => {
    dispatch(userSaveTrackStart());
    try {
      const userDocRef = doc(db, "users", userId);
      const userDocSnap = await getDoc(userDocRef);
      if (!userDocSnap.exists()) {
        throw new Error("User document not found.");
      }

      const userData = userDocSnap.data();

      // Extract only the necessary fields
      const trackToSave = {
        songId: track.songId,
        songTitle: track.songTitle,
        artiste: track.artiste,
        songUrl: track.songUrl,
      };

      const updatedLikedTracks = [...userData.likedTracks, trackToSave];

      await updateDoc(userDocRef, { likedTracks: updatedLikedTracks });

      dispatch(userSaveTrackSuccess(updatedLikedTracks));
    } catch (err) {
      dispatch(userSaveTrackError(err.message));
    }
  };
};

// User removing a track action creators
const userRemoveTrackStart = () => ({ type: "REMOVE_TRACK_START" });
const userRemoveTrackSuccess = (likedTracks) => ({ type: "REMOVE_TRACK_SUCCESS", likedTracks });
const userRemoveTrackError = (err) => ({ type: "REMOVE_TRACK_ERROR", err });

export const userRemoveTrack = (userId, songId) => {
  return async (dispatch, getState) => {
    dispatch(userRemoveTrackStart());
    try {
      const userDocRef = doc(db, "users", userId);
      const userDocSnap = await getDoc(userDocRef);
      if (!userDocSnap.exists()) {
        throw new Error("User document not found.");
      }

      const userData = userDocSnap.data();
      const updatedLikedTracks = userData.likedTracks.filter(track => track.songId !== songId);

      await updateDoc(userDocRef, { likedTracks: updatedLikedTracks });

      dispatch(userRemoveTrackSuccess(updatedLikedTracks));
    } catch (err) {
      dispatch(userRemoveTrackError(err.message));
    }
  };
};

export const UPDATE_USER_PROFILE = 'UPDATE_USER_PROFILE';
export const UPDATE_USER_PROFILE_SUCCESS = 'UPDATE_USER_PROFILE_SUCCESS';
export const UPDATE_USER_PROFILE_FAILURE = 'UPDATE_USER_PROFILE_FAILURE';

export const updateUserProfile = (userId, newEmail, password) => {
  return async (dispatch) => {
    dispatch({ type: UPDATE_USER_PROFILE });

    try {
      const user = auth.currentUser;
      if (!user) {
        throw new Error('User not authenticated.');
      }

      // Reauthenticate user
      const credential = EmailAuthProvider.credential(user.email, password);
      await reauthenticateWithCredential(user, credential);

      // Check if the new email is already in use
      const userCollection = collection(db, 'users');
      const emailQuery = query(userCollection, where('email', '==', newEmail));
      const querySnapshot = await getDocs(emailQuery);

      if (!querySnapshot.empty) {
        throw new Error('Email already in use. Please choose a different email.');
      }

      // Update the user's email in Firebase Authentication
      await updateEmail(user, newEmail);

      // Update the user's email in Firestore
      const userDocRef = doc(db, 'users', userId);
      await updateDoc(userDocRef, { email: newEmail });

      dispatch({ type: UPDATE_USER_PROFILE_SUCCESS, payload: newEmail });
    } catch (error) {
      let errorMessage;
      if (error.code === 'auth/invalid-credential' || 'auth/wrong-password') {
        errorMessage = 'Incorrect password. Please try again.';
      } else {
        errorMessage = error.message;
      }
      dispatch({ type: UPDATE_USER_PROFILE_FAILURE, payload: errorMessage });
      throw new Error(errorMessage); // Rethrow the error to propagate it further
    }
  };
};

