import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllAds } from '../../store/actions/adAction';

const NativeAdComponent = React.memo(() => {
  const dispatch = useDispatch();
  const ads = useSelector((state) => state.ads.allAds);
  const adContainerRef = useRef(null);

  useEffect(() => {
    // Fetch ads when the component mounts
    dispatch(fetchAllAds());
  }, [dispatch]);

  useEffect(() => {
    if (ads && ads.length > 0) {
      // Find the Native Banner ad
      const nativeBannerAd = ads.find((ad) => ad.type === 'Native Banner');
  
      if (nativeBannerAd && adContainerRef.current) {
        // Clear the ad container
        adContainerRef.current.innerHTML = '';
  
        // Extract the adTag content (which contains the <script> and <div> for the Native Banner)
        const adTag = nativeBannerAd.adTag;
       // console.log("Native Banner tag:", adTag); 
  
        // Insert the adTag (which should include both the script and div)
        adContainerRef.current.innerHTML = adTag;
  
        // Optionally, if the adTag includes a <script> tag, the script will be automatically executed
      }
    } else {
     //console.log('No Native Banner ad available.');
    }
  }, [ads]);
  


  return (
    <div
      ref={adContainerRef}
      style={{ textAlign: 'center', margin: '10px 0' }}
      className="nativeAdContainer"
    >
      {/* Native Banner ad will load dynamically here */}
    </div>
  );
});

export default NativeAdComponent;
